@charset "utf-8";


/* Colors */
$text-color: #40514e !default;
$muted-text-color: #40514e !default;
$primary-color: #448aff !default;
$border-color: mix(#fff, #40514e, 75%) !default;
$link-color: #448aff !default;
$masthead-link-color: $text-color !default;
$masthead-link-color-hover: $text-color !default;
$navicon-link-color-hover: mix(#fff, $text-color, 80%) !default;

/* contrast syntax highlighting (base16) */
$base00: #000000 !default;
$base01: #242422 !default;
$base02: #484844 !default;
$base03: #6c6c66 !default;
$base04: #918f88 !default;
$base05: #b5b3aa !default;
$base06: #d9d7cc !default;
$base07: #fdfbee !default;
$base08: #ff6c60 !default;
$base09: #e9c062 !default;
$base0a: #ffffb6 !default;
$base0b: #a8ff60 !default;
$base0c: #c6c5fe !default;
$base0d: #96cbfe !default;
$base0e: #ff73fd !default;
$base0f: #b18a3d !default;

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials
